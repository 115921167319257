import request from '@/utils/request'


// 查询提现申请列表
export function listWithdrawal(query) {
  return request({
    url: '/withdrawal/withdrawal/list',
    method: 'get',
    params: query
  })
}

// 查询提现申请分页
export function pageWithdrawal(query) {
  return request({
    url: '/withdrawal/withdrawal/page',
    method: 'get',
    params: query
  })
}

// 查询提现申请详细
export function getWithdrawal(data) {
  return request({
    url: '/withdrawal/withdrawal/detail',
    method: 'get',
    params: data
  })
}

// 新增提现申请
export function addWithdrawal(data) {
  return request({
    url: '/withdrawal/withdrawal/add',
    method: 'post',
    data: data
  })
}

// 修改提现申请
export function updateWithdrawal(data) {
  return request({
    url: '/withdrawal/withdrawal/edit',
    method: 'post',
    data: data
  })
}

// 删除提现申请
export function delWithdrawal(data) {
  return request({
    url: '/withdrawal/withdrawal/delete',
    method: 'post',
    data: data
  })
}
