<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
<!--      <a-form-model-item label="用户id,外键{zb_user.id}" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入用户id,外键{zb_user.id}" />
      </a-form-model-item>
      <a-form-model-item label="提现金额" prop="money" >
        <a-input v-model="form.money" placeholder="请输入提现金额" />
      </a-form-model-item>
      <a-form-model-item label="手续费" prop="commission" >
        <a-input v-model="form.commission" placeholder="请输入手续费" />
      </a-form-model-item>
      <a-form-model-item label="实际支付金额" prop="payMoney" >
        <a-input v-model="form.payMoney" placeholder="请输入实际支付金额" />
      </a-form-model-item>
      <a-form-model-item label="提现方式,0-微信,1-支付宝" prop="type" >
      </a-form-model-item>-->
      <a-form-model-item label="状态" prop="status" >
        <a-radio-group v-model="form.status">
          <a-radio :value="1">同意</a-radio>
          <a-radio :value="2">驳回</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="审核原因" prop="reason" >
        <a-input v-model="form.reason" placeholder="请输入审核原因" />
      </a-form-model-item>
<!--      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getWithdrawal, addWithdrawal, updateWithdrawal } from '@/api/withdrawal/withdrawal'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        /*userId: null,

        money: null,

        commission: null,

        payMoney: null,

        type: null,*/

        status: 0,

        reason: null,

        /*createTime: null,

        remark: null,*/

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        /*userId: [
          { required: true, message: '用户id,外键{zb_user.id}不能为空', trigger: 'blur' }
        ],
        money: [
          { required: true, message: '提现金额不能为空', trigger: 'blur' }
        ],
        commission: [
          { required: true, message: '手续费不能为空', trigger: 'blur' }
        ],
        payMoney: [
          { required: true, message: '实际支付金额不能为空', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '提现方式,0-微信,1-支付宝不能为空', trigger: 'change' }
        ],*/
        status: [
          { required: true, message: '审核状态不能为空', trigger: 'blur' }
        ],
        reason: [
          { required: true, message: '审核原因不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        money: null,
        commission: null,
        payMoney: null,
        type: null,
        status: 0,
        reason: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getWithdrawal({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '审核'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateWithdrawal(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addWithdrawal(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
